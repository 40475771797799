import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/main'),
    meta: {
      src: './pages/app/index.html',
      title: '首页'
    }
  },
  {
    path: '/node',
    component: () => import('@/views/main'),
    meta: {
      src: './pages/app/bianyuan.html',
      title: '边缘节点'
    }
  },
  {
    path: '/app',
    component: () => import('@/views/main'),
    meta: {
      title: '工业应用'
    },
    children: [
      {
        path: 'visualization',
        component: () => import('@/views/main'),
        meta: {
          src: './pages/app/datashi.html',
          title: '数据可视化'
        }
      },
      {
        path: 'interactive',
        component: () => import('@/views/main'),
        meta: {
          title: '人机交互'
        },
        children: [
          {
            path: 'ar',
            component: () => import('@/views/main'),
            meta: {
              href: 'http://www.3d.tz-group.com',
              title: 'AR/VR展示'
            }
          },
          {
            path: 'dataDocking',
            component: () => import('@/views/main'),
            meta: {
              href:
                'https://demo.laozicloud.com/beichen/?from=si%20%20nglemessage',
              title: '数据对接'
            }
          },
          {
            path: 'online3D',
            component: () => import('@/views/main'),
            meta: {
              src: './pages/app/3dmodel.html',
              title: '在线3D模型库'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/appMarket',
    component: () => import('@/views/main'),
    meta: {
      src: './pages/appMarket/index.html',
      title: '工业APP市场'
    }
  },
  {
    path: '/support',
    component: () => import('@/views/main'),
    meta: {
      src: './pages/app/jrzc.html',
      title: '金融支持'
    }
  },
  {
    path: '/bigData',
    // redirect: '/bigData/test',
    component: () => import('@/views/main'),
    meta: {
      title: '工业大数据'
    },
    children: [
      {
        path: 'test',
        component: () => import('@/views/main'),
        meta: {
          src: './pages/bigData/index.html',
          title: '数据测试'
        }
      },
      {
        path: 'analysis',
        component: () => import('@/views/main'),
        meta: {
          src: './pages/bigData/analysis.html',
          title: '数据分析'
        }
      }
    ]
  },
  {
    path: '/plant',
    component: () => import('@/views/main'),
    meta: {
      title: '平台间调用'
    },
    children: [
      {
        path: 'api',
        component: () => import('@/views/main'),
        meta: {
          src: './pages/app/apijiekou.html',
          title: '数据调用'
        }
      },
      {
        path: 'application',
        component: () => import('@/views/main'),
        meta: {
          src: './pages/app/yingyong.html',
          title: '应用调用'
        }
      }
    ]
  },
  {
    path: '/paas',
    component: () => import('@/views/main'),
    meta: {
      src: './pages/djj/paas.html',
      title: 'PAAS服务'
    }
  },
  {
    path: '/dataManagement',
    component: () => import('@/views/main'),
    meta: {
      src: './pages/djj/dataManage.html',
      title: '工业数据管理'
    }
  },
  {
    path: '/model',
    component: () => import('@/views/main'),
    meta: {
      src: './pages/djj/productivity.html',
      title: '工业模型'
    }
  }
]
function getRoutes(routes) {
  if (routes) {
    let _routes = []
    routes.forEach((item) => {
      if (!item.meta.href) {
        if (item.children) {
          _routes.push({ ...item, children: getRoutes(item.children) })
        } else {
          _routes.push(item)
        }
      }
    })
    return _routes
  } else {
    return null
  }
}
const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      ...getRoutes(constantRoutes),
      // 登录
      {
        path: '/login',
        component: () => import('@/views/login')
      },
      {
        path: '/register',
        component: () => import('@/views/register')
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/404'),
        hidden: true
      },
      // 404 page must be placed at the end !!!
      { path: '*', redirect: '/404', hidden: true }
    ]
  })

const router = createRouter()

export default router
