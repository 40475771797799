import Vue from 'vue'
import App from './App'
import router from './router'

/* 引入样式 start */
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import '@/style/index.scss'
/* 引入样式 end */

/* Vue.use start */
import ElementUI from 'element-ui' // use ElementUI
Vue.use(ElementUI)

/* 其他 start */
import '@/permission' // permission control
Vue.config.productionTip = false
import request from '@/utils/request'
window.request = request

Vue.prototype.$request = request
var vm = new Vue({
  el: '#app',
  router,
  render: (h) => h(App)
})
window.$router = vm.$router
// import Oss from '@/utils/ali-oss'
// window.oss = new Oss(
//   {
//     region: 'oss-cn-hangzhou',
//     bucket: 'xinhui2017',
//     accessKeyId: 'LTAI4GDiv2788VVRTidpezNz',
//     accessKeySecret: 'YZefvAXlJHGucYTEHZtDxdL0pRUsdR',
//     secure: true
//   },
//   {
//     chunkSize: 1024 * 1024 * 50,
//     basePath: 'analysisFile/imatrix'
//   }
// )
