import axios from 'axios'
import router from '@/router'
import { MessageBox, Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'

const service = axios.create({
  baseURL: 'http://api.imartrixplatform.test.tz-group.com',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000
})

service.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers['Custom'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code == 2100) {
      // 登录失败
      // Message({
      //   message: res.msg,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // to re-login
      MessageBox.confirm(res.msg, '提醒', {
        confirmButtonText: '登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeToken()
        location.reload()
      })
      return Promise.reject(res.msg)
    } else if (!res.success) {
      Message({
        message: res.message,
        type: 'error'
      })
      return Promise.reject(res.message)
    } else {
      // 请求成功
      return res
    }
  },
  (error) => {
    let message = ''
    if (typeof error === 'string') {
      message = error
    } else if (error.message === 'Network Error') {
      //网络连接失败
      message = '网络连接失败，请检查网络！'
    } else if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') !== -1
    ) {
      //请求超时
      message = '请求超时了，请检查网络！'
    } else if (error && error.response) {
      switch (error.response.status) {
        case 400:
          message = '请求错误'
          break

        case 401:
          message = '当前登录已过期，请重新登录'
          if (!window.flag401) {
            removeToken()

            // 跳转到登录页
            router.replace({
              path: '/login',
              query: { redirect: router.currentRoute.fullPath }
            })
            window.flag401 = true
          }

          break

        case 403:
          message = '拒绝访问'
          break

        case 404:
          message = `请求地址出错: ${error.response.config.url}`
          break

        case 408:
          message = '请求超时'
          break

        case 500:
          message = '服务器内部错误'
          break

        case 501:
          message = '服务未实现'
          break

        case 502:
          message = '网关错误'
          break

        case 503:
          message = '服务不可用'
          break

        case 504:
          message = '网关超时'
          break

        case 505:
          message = 'HTTP版本不受支持'
          break

        default:
      }
    }
    if (!window.flag401) {
      Message({
        message,
        type: 'error',
        duration: 3 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service
